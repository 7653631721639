import React from 'react'

const MediaBuyingIcon = props => (
  <svg viewBox="0 0 32 32" data-ois="media-buying">

  <polygon className="ois-supporting-shape" points="6,12.8 10.3,26.5 25.4,21.3 " ois-scale-in="true" />
  <polyline className="ois-supporting-stroke" points="25.4,11.8 25.4,5.5 1.8,5.5 1.8,20.8 18.2,20.8 "/>
  <line className="ois-supporting-stroke" x1="1.8" y1="7.3" x2="25.4" y2="7.3"/>
  <line className="ois-supporting-stroke" x1="23.5" y1="7.3" x2="23.5" y2="5.5"/>
  <line className="ois-supporting-stroke" x1="7.3" y1="7.3" x2="7.3" y2="5.5"/>
  <line className="ois-supporting-stroke" x1="5.5" y1="7.3" x2="5.5" y2="5.5"/>
  <line className="ois-supporting-stroke" x1="3.6" y1="7.3" x2="3.6" y2="5.5"/>
  <path className="ois-supporting-stroke" d="M19.7,26c-0.8,0-1.4-0.6-1.4-1.4v-0.7V13.1h2.9" />
  <path className="ois-supporting-stroke" d="M19.7,26h9.1c0.8,0,1.4-0.6,1.4-1.4V11.6h-9.1v12.9C21.1,25.3,20.4,26,19.7,26"/>

  <rect x="4.1" y="10.1" className="ois-focus-stroke" width="3.8" height="8.6" ois-media-buying-idx="1"/>
  <rect x="22.9" y="19.6" className="ois-focus-stroke" width="5.5" height="4.4" ois-media-buying-idx="2"/>

  <g ois-media-buying-group="1">
    <line className="ois-supporting-stroke" x1="9.9" y1="10.1" x2="16.5" y2="10.1" ois-undraw="true" />
    <line className="ois-supporting-stroke" x1="9.9" y1="11.7" x2="15.5" y2="11.7" ois-undraw="true" />
    <line className="ois-supporting-stroke" x1="9.9" y1="13.3" x2="16" y2="13.3" ois-undraw="true" />
    <line className="ois-supporting-stroke" x1="9.9" y1="15" x2="15.6" y2="15" ois-undraw="true" />
    <line className="ois-supporting-stroke" x1="9.9" y1="16.6" x2="12.5" y2="16.6" ois-undraw="true" />
  </g>
  <g ois-media-buying-group="3">
    <line className="ois-supporting-stroke" x1="22.4" y1="14" x2="28.9" y2="14" ois-undraw="true" />
    <line className="ois-supporting-stroke" x1="22.4" y1="15.6" x2="28.9" y2="15.6" ois-undraw="true" />
    <line className="ois-supporting-stroke" x1="22.4" y1="17.3" x2="28.9" y2="17.3" ois-undraw="true" />
  </g>
  <g ois-media-buying-group="2">
    <line className="ois-supporting-stroke" x1="3.6" y1="15.3" x2="16.7" y2="15.3" ois-no-draw="true" ois-undraw="true" />
    <line className="ois-supporting-stroke" x1="3.6" y1="17" x2="15.8" y2="17" ois-no-draw="true" ois-undraw="true" />
    <line className="ois-supporting-stroke" x1="3.6" y1="18.6" x2="9.2" y2="18.6" ois-no-draw="true" ois-undraw="true" />
  </g>
  <g ois-media-buying-group="4">
    <line className="ois-supporting-stroke" x1="22.4" y1="19.5" x2="28.9" y2="19.5" ois-no-draw="true" ois-undraw="true" />
    <line className="ois-supporting-stroke" x1="22.4" y1="21.2" x2="28.9" y2="21.2" ois-no-draw="true" ois-undraw="true" />
    <line className="ois-supporting-stroke" x1="22.4" y1="22.8" x2="28.9" y2="22.8" ois-no-draw="true" ois-undraw="true" />
  </g>

  </svg>
)

export default MediaBuyingIcon
