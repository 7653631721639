import React from 'react'

const CopywritingIcon = () => (
  <svg viewBox="0 0 32 32" data-ois="copywriting">

    <circle className="ois-supporting-shape" cx="20.2" cy="19.4" r="4.7" ois-scale-in="true" ois-copywriting-idx="1"/>

    <line className="ois-tertiary-stroke" x1="8.9" y1="17.5" x2="13.8" y2="12.5" ois-no-draw="true" ois-copywriting-idx="2" ois-undraw="true" />
    <line className="ois-tertiary-stroke" x1="10.9" y1="19.5" x2="15.9" y2="14.6" ois-no-draw="true" ois-copywriting-idx="3" ois-undraw="true" />
    <line className="ois-tertiary-stroke" x1="13" y1="21.6" x2="18" y2="16.7" ois-no-draw="true" ois-copywriting-idx="4" ois-undraw="true" />

    <path className="ois-supporting-stroke" d="M8.9,13.1c-1-1-2.5-1-3.5,0"/>
    <path className="ois-supporting-stroke" d="M8.9,13.1l0.8,0.8l7.5-7.5l-0.8-0.8c-1-1-2.5-1-3.5,0l-7.5,7.5c-1,1-1,2.5,0,3.5l8,8c1,1,1,2.5,0,3.5"/>
    <path className="ois-supporting-stroke" d="M13.4,28c-1,1-2.5,1-3.5,0l-0.8-0.8l3.5-3.5"/>
    <line className="ois-supporting-stroke" x1="16.6" y1="12.8" x2="13.7" y2="9.9"/>
    <path className="ois-supporting-stroke" d="M13.4,28l7.5-7.5c1-1,1-2.5,0-3.5l-3.1-3.1"/>
    <polygon className="ois-focus-stroke" points="16.6,15.2 15.4,13.9 26.7,3.2 27.3,3.8 "/>
    <path className="ois-focus-stroke" d="M16.3,14.9c-0.7,0.7-1.3,1.3-1.4,2.1l-2.3,1.2l-0.3-0.3l1.2-2.3c0.8-0.1,1.4-0.7,2.1-1.4"/>

  </svg>

)

export default CopywritingIcon
