import React from 'react'

const WebDevelopmentIcon = () => (
  <svg viewBox="0 0 32 32" data-ois="web-development">

    <rect x="5.6" y="11" className="ois-supporting-shape" width="16.4" height="12.4" ois-scale-in="true" ois-web-development-idx="4"/>
    <rect x="4" y="5.3" className="ois-supporting-stroke" width="24" height="16.5"/>
    <path className="ois-supporting-stroke" d="M19,21.8c0,1.6,0.8,4,1.3,5h-8.6c0.5-1,1.3-3.4,1.3-5"/>
    <g ois-web-development-idx="3">
      <line className="ois-focus-stroke" x1="12.1" y1="9.3" x2="13.5" y2="9.3"/>
      <line className="ois-focus-stroke" x1="13.9" y1="9.3" x2="15.9" y2="9.3"/>
      <line className="ois-focus-stroke" x1="16.3" y1="9.3" x2="17.3" y2="9.3"/>
      <line className="ois-focus-stroke" x1="17.6" y1="9.3" x2="18.6" y2="9.3"/>
      <line className="ois-focus-stroke" x1="19"   y1="9.3" x2="21"   y2="9.3"/>
      <line className="ois-focus-stroke" x1="21.5" y1="9.3" x2="22.9" y2="9.3"/>
      <line className="ois-focus-stroke" x1="8.2"  y1="9.3" x2="11.6" y2="9.3"/>

      <line className="ois-focus-stroke" x1="8.2"  y1="10.9" x2="9.7"  y2="10.9"/>
      <line className="ois-focus-stroke" x1="10.1" y1="10.9" x2="10.8" y2="10.9"/>
      <line className="ois-focus-stroke" x1="11.2" y1="10.9" x2="13.9" y2="10.9"/>
      <line className="ois-focus-stroke" x1="14.3" y1="10.9" x2="15.3" y2="10.9"/>
      <line className="ois-focus-stroke" x1="15.6" y1="10.9" x2="18.5" y2="10.9"/>
      <line className="ois-focus-stroke" x1="18.9" y1="10.9" x2="20"   y2="10.9"/>
      <line className="ois-focus-stroke" x1="20.5" y1="10.9" x2="21.5" y2="10.9"/>

      <line className="ois-focus-stroke" x1="8.2"  y1="12.4" x2="10.8" y2="12.4"/>
      <line className="ois-focus-stroke" x1="11.2" y1="12.4" x2="12"   y2="12.4"/>
      <line className="ois-focus-stroke" x1="12.4" y1="12.4" x2="14.2" y2="12.4"/>
      <g ois-web-development-idx="6">
        <line className="ois-focus-stroke" x1="14.6" y1="12.4" x2="16.4" y2="12.4"/>
        <line className="ois-focus-stroke" x1="16.8" y1="12.4" x2="19.4" y2="12.4"/>
        <line className="ois-focus-stroke" x1="19.8" y1="12.4" x2="20.9" y2="12.4"/>
        <line className="ois-focus-stroke" x1="21.3" y1="12.4" x2="23.8" y2="12.4"/>
      </g>

      <line className="ois-focus-stroke" x1="8.2"  y1="14.7" x2="9.7"  y2="14.7"/>
      <line className="ois-focus-stroke" x1="10.1" y1="14.7" x2="11.5" y2="14.7"/>
      <line className="ois-focus-stroke" x1="12"   y1="14.7" x2="15.9" y2="14.7"/>
      <line className="ois-focus-stroke" x1="16.3" y1="14.7" x2="17.2" y2="14.7"/>
      <line className="ois-focus-stroke" x1="17.8" y1="14.7" x2="19.2" y2="14.7"/>
      <line className="ois-focus-stroke" x1="19.6" y1="14.7" x2="20.3" y2="14.7"/>
      <line className="ois-focus-stroke" x1="20.8" y1="14.7" x2="23.5" y2="14.7"/>

      <line className="ois-focus-stroke" x1="8.2"  y1="16.3" x2="9.7"  y2="16.3"/>
      <line className="ois-focus-stroke" x1="10.1" y1="16.3" x2="13.8" y2="16.3"/>

      <g ois-web-development-idx="5">
        <line className="ois-focus-stroke" x1="14.2" y1="16.3" x2="16.9" y2="16.3"/>
        <line className="ois-focus-stroke" x1="17.3" y1="16.3" x2="18.3" y2="16.3"/>
        <line className="ois-focus-stroke" x1="18.7" y1="16.3" x2="21.5" y2="16.3"/>
      </g>

      <line className="ois-focus-stroke" x1="8.2"  y1="17.9" x2="12.8" y2="17.9"/>
      <line className="ois-focus-stroke" x1="13.2" y1="17.9" x2="14"   y2="17.9"/>
      <line className="ois-focus-stroke" x1="14.4" y1="17.9" x2="15.2" y2="17.9"/>
      <line className="ois-focus-stroke" x1="15.7" y1="17.9" x2="17.4" y2="17.9"/>
      <line className="ois-focus-stroke" x1="17.8" y1="17.9" x2="19.4" y2="17.9"/>

    </g>
    <polyline className="ois-focus-stroke" points="7.6,20.1 0.9,13.4 7.6,6.8 " ois-web-development-idx="1"/>
    <polyline className="ois-focus-stroke" points="24.4,20.1 31.1,13.4 24.4,6.8 " ois-web-development-idx="2"/>

  </svg>
)

export default WebDevelopmentIcon
