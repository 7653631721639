import React from 'react'

const ArIcon = () => (
  <svg viewBox="0 0 32 32" data-ois="ar">
    <rect x="6.7" y="9.5" className="ois-supporting-shape" width="21.4" height="12.1" ois-opacity-in="true" ois-ar-idx="2"/>
<g ois-ar-idx="1">
    <rect x="16.8" y="12.1" className="ois-supporting-stroke" width="4.6" height="8.1"/>

    <line className="ois-supporting-stroke" x1="18.4" y1="13.2" x2="18.4" y2="14.3"/>
    <line className="ois-supporting-stroke" x1="19.9" y1="13.2" x2="19.9" y2="14.3"/>
    <line className="ois-supporting-stroke" x1="18.4" y1="14.7" x2="18.4" y2="15.8"/>
    <line className="ois-supporting-stroke" x1="19.9" y1="14.7" x2="19.9" y2="15.8"/>
    <line className="ois-supporting-stroke" x1="18.4" y1="16.3" x2="18.4" y2="17.4"/>
    <line className="ois-supporting-stroke" x1="19.1" y1="18.3" x2="19.1" y2="20.2"/>
    <line className="ois-supporting-stroke" x1="19.9" y1="16.3" x2="19.9" y2="17.4"/>
    <rect x="1.7" y="12.1" className="ois-supporting-stroke" width="6.3" height="8.1"/>
    <rect x="21.5" y="15.2" className="ois-supporting-stroke" width="4.4" height="5"/>
    <rect x="25.9" y="13.5" className="ois-supporting-stroke" width="4.4" height="6.7"/>
    <rect x="8" y="13.7" className="ois-supporting-stroke" width="4.4" height="6.5"/>
    <line className="ois-supporting-stroke" x1="4.9" y1="13.2" x2="4.9" y2="14.3"/>
    <line className="ois-supporting-stroke" x1="6.3" y1="13.2" x2="6.3" y2="14.3"/>
    <line className="ois-supporting-stroke" x1="4.9" y1="14.7" x2="4.9" y2="15.8"/>
    <line className="ois-supporting-stroke" x1="6.3" y1="14.7" x2="6.3" y2="15.8"/>
    <line className="ois-supporting-stroke" x1="4.9" y1="16.3" x2="4.9" y2="17.4"/>
    <line className="ois-supporting-stroke" x1="3.4" y1="13.2" x2="3.4" y2="14.3"/>
    <line className="ois-supporting-stroke" x1="3.4" y1="14.7" x2="3.4" y2="15.8"/>
    <line className="ois-supporting-stroke" x1="3.4" y1="16.3" x2="3.4" y2="17.4"/>
    <line className="ois-supporting-stroke" x1="4.9" y1="18.3" x2="4.9" y2="20.2"/>
    <line className="ois-supporting-stroke" x1="6.3" y1="16.3" x2="6.3" y2="17.4"/>
    <line className="ois-supporting-stroke" x1="27.4" y1="14.7" x2="27.4" y2="15.8"/>
    <line className="ois-supporting-stroke" x1="28.8" y1="14.7" x2="28.8" y2="15.8"/>
    <line className="ois-supporting-stroke" x1="27.4" y1="16.3" x2="27.4" y2="17.4"/>
    <line className="ois-supporting-stroke" x1="28.1" y1="18.3" x2="28.1" y2="20.2"/>
    <line className="ois-supporting-stroke" x1="23.7" y1="18.3" x2="23.7" y2="20.2"/>
    <line className="ois-supporting-stroke" x1="24.8" y1="17.1" x2="22.5" y2="17.1"/>
    <line className="ois-supporting-stroke" x1="28.8" y1="16.3" x2="28.8" y2="17.4"/>
</g>
<line className="ois-supporting-stroke" x1="10.6" y1="7.8" x2="21.5" y2="7.8"/>
<line className="ois-supporting-stroke" x1="15.1" y1="24.5" x2="16.9" y2="24.5"/>
<line className="ois-supporting-stroke" x1="21.5" y1="22.9" x2="10.6" y2="22.9"/>
<path className="ois-supporting-stroke" d="M10.6,25.3c0,0.5,0.4,0.9,0.9,0.9h9.1c0.5,0,0.9-0.4,0.9-0.9V6.7c0-0.5-0.4-0.9-0.9-0.9h-9.1
        c-0.5,0-0.9,0.4-0.9,0.9V25.3z"/>
<line className="ois-focus-stroke" x1="13.9" y1="13.2" x2="13.9" y2="14.3"/>
<line className="ois-focus-stroke" x1="15.3" y1="13.2" x2="15.3" y2="14.3"/>
<line className="ois-focus-stroke" x1="13.9" y1="11.6" x2="13.9" y2="12.7"/>
<line className="ois-focus-stroke" x1="15.3" y1="11.6" x2="15.3" y2="12.7"/>
<line className="ois-focus-stroke" x1="13.9" y1="14.7" x2="13.9" y2="15.8"/>
<line className="ois-focus-stroke" x1="15.3" y1="14.7" x2="15.3" y2="15.8"/>
<line className="ois-focus-stroke" x1="13.9" y1="16.3" x2="13.9" y2="17.4"/>
<line className="ois-focus-stroke" x1="14.6" y1="18.3" x2="14.6" y2="20.2"/>
<line className="ois-focus-stroke" x1="15.3" y1="16.3" x2="15.3" y2="17.4"/>
<rect x="12.4" y="10.6" className="ois-focus-stroke" width="4.4" height="9.6" ois-ar-idx="3"/>
  </svg>
)

export default ArIcon