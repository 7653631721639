import React from 'react'

const ExperientialIcon = () => (
  <svg viewBox="0 0 32 32" data-ois="experiential">
    <polygon className="ois-supporting-shape" points="12.2,18.7 16,20.9 19.8,18.7 19.8,13.7 16,11.4 12.2,13.7   " ois-scale-in="true" data-experiential-idx="1"/>

    <path className="ois-focus-stroke" d="M5.9,13.1c0.2,0,0.5-0.1,0.7-0.1"/>
    <path className="ois-focus-stroke" d="M26.1,13.1c-0.2,0-0.4-0.1-0.7-0.1"/>
    <polygon className="ois-supporting-stroke" points="6.6,22.4 16,28 25.4,22.4 25.4,9.9 16,4.3 6.6,9.9 "/>
    <polyline className="ois-supporting-stroke" points="25.4,9.9 16,15.6 6.6,9.9 "/>
    <line className="ois-supporting-stroke" x1="16" y1="15.6" x2="16" y2="27.9"/>
    <polygon className="ois-focus-stroke" points="11.6,17.6 14,20 11.6,22.4 "/>
    <path className="ois-focus-stroke" d="M20.5,20c6.3-0.5,10.9-2,10.9-3.8c0-1.2-2.1-2.3-5.3-3"/>
    <path className="ois-focus-stroke" d="M11.6,20c-6.3-0.5-11-2-11-3.8c0-1.2,2.1-2.3,5.3-3"/>

    <g className="ois-morph-frames">
      <polygon className="ois-morph-frame" points="12.2,18.7 16,20.9 19.8,18.7 19.8,13.7 16,11.4 12.2,13.7  " ois-morph-frame="0"/>
      <polygon className="ois-morph-frame" points="16,26.8 6.3,10 25.7,10   " ois-morph-frame="1"/>
      <circle fill="none" className="ois-morph-frame" className="st0" cx="16" cy="15.6" r="13.9" ois-morph-frame="2"/>
    </g>
  </svg>
)

export default ExperientialIcon