import React from 'react'

const ExpertiseIcon = () => (
  <svg viewBox="0 0 32 32" data-ois="expertise">
    <g>
      <polygon className="ois-supporting-shape ois-transparent" points="5,17.4 15.4,27.5 11.4,18.8  " expertise-morph-frame="1" />
      <polygon className="ois-supporting-shape ois-transparent" points="5,16.9 11.4,18.8 8.2,13.1       " expertise-morph-frame="2" />
      <polygon className="ois-supporting-shape ois-transparent" points="8.2,13.1 11.4,18.8 15.8,13.1  " expertise-morph-frame="3" />
      <polygon className="ois-supporting-shape ois-transparent" points="15.8,13.1 11.4,18.8 20.1,19   " expertise-morph-frame="4" />
      <polygon className="ois-supporting-shape ois-transparent" points="11.8,19 15.8,28.2 20.1,19       " expertise-morph-frame="5" />
      <polygon className="ois-supporting-shape ois-transparent" points="15.8,13.1 20.1,19 23.4,13.1   " expertise-morph-frame="6" />
      <polygon className="ois-supporting-shape ois-transparent" points="23.4,13.1 27.1,16.9 20.1,19   " expertise-morph-frame="7" />
      <polygon className="ois-supporting-shape ois-transparent" points="20.1,19 27.1,16.9 16.2,27.5   " expertise-morph-frame="8" />
    </g>

    <polygon className="ois-supporting-stroke" points="23.4,13.1 27.1,16.9 15.8,27.8 4.5,16.9 8.2,13.1 "/>
    <g>
      <line className="ois-supporting-stroke" x1="11.5" y1="19" x2="15.8" y2="27.8"/>
      <line className="ois-supporting-stroke" x1="20.1" y1="19" x2="15.8" y2="27.8"/>
    </g>
    <polyline className="ois-supporting-stroke" points="4.5,16.9 11.5,19 20.1,19 27.1,16.9 "/>
    <polyline className="ois-supporting-stroke" points="23.4,13.1 20.1,19 15.8,13.1 11.5,19 8.2,13.1 " strokeLinejoin="round"/>
    <line className="ois-focus-stroke" x1="15.8" y1="10.5" x2="15.8" y2="4.3" expertise-idx="2"/>
    <line className="ois-focus-stroke" x1="10.7" y1="10.5" x2="8.5" y2="6.1" expertise-idx="2"/>
    <line className="ois-focus-stroke" x1="5" y1="12.5" x2="2.8" y2="10.9" expertise-idx="2"/>
    <line className="ois-focus-stroke" x1="20.9" y1="10.5" x2="23.1" y2="6.1" expertise-idx="2"/>
    <line className="ois-focus-stroke" x1="26.6" y1="12.5" x2="28.8" y2="10.9" expertise-idx="2"/>


  </svg>
)

export default ExpertiseIcon
