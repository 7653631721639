import React from 'react'

const BrandDevelopmentIcon = () => (
  <svg viewBox="0 0 32 32" data-ois="brand-development">
    
    <path className="ois-supporting-shape" d="M13.4,9.8c0-3.2,3.2-5.9,7.2-5.9s7.2,2.6,7.2,5.9c0,3.2-3.2,5.9-7.2,5.9c-1.2,0-2.4-0.3-3.4-0.7l-2,1l0.1-2.3
      C14.1,12.7,13.4,11.3,13.4,9.8z" ois-scale-in="true" />
    <g>
      <path className="ois-focus-stroke ois-low-miter" d="M6.6,13.8h5.7V26h4.4c1.9,0,3.5-1.6,3.5-3.5S18.6,19,16.7,19h-0.9c1.9,0,3.5-1.6,3.5-3.5s-1.6-3.5-3.5-3.5H6.6
        " ois-brand-development-idx="4"/>
      <line className="ois-focus-stroke" x1="10.6" y1="26" x2="6.6" y2="26"/>
      <polyline className="ois-focus-stroke" points="6.6,24.2 10.1,24.2 10.1,15.8   "/>
    </g>
    <path className="ois-hide" d="M15.8,19c1.9,0,3.5-1.6,3.5-3.5s-1.6-3.5-3.5-3.5"/>
    <polyline className="ois-supporting-stroke" points="16.8,23.5 14.1,23.5 14.1,28.2 5,28.2 5,19 6.4,19 " ois-brand-development-idx="5"/>
    <polyline className="ois-supporting-stroke" points="21.9,28.2 25.7,28.2 25.7,24.5 "/>
    <polyline className="ois-supporting-stroke" points="8.8,5.9 5,5.9 5,9.7 "/>

    <g ois-brand-development-idx="3">
      <line className="ois-supporting-stroke" x1="6.4" y1="19" x2="8.3" y2="19" ois-brand-development-idx="1"/>
      <polyline className="ois-supporting-stroke" points="6.5,17.1 8.3,19 6.5,20.8  " ois-brand-development-idx="2"/>
    </g>

    <g className="ois-morph-frames">
      <path className="ois-morph-frame" d="M6.6,13.8h5.7l0,5.2l0,7h4.4c1.6,0,2.8-1,3.6-2.4c0.6-1.2,1-2.8,1-4.3c0-1.8-0.5-3.7-1.4-5.1
        c-0.9-1.3-2.2-2.2-4-2.2H6.6" ois-morph-frames="2"/>
      <path className="ois-morph-frame" d="M6.6,13.8h5.7L8.8,19l3.5,7h4.4c1.6,0,2.8-1,3.6-2.4c0.6-1.2,1-2.8,1-4.3c0-1.8-0.5-3.7-1.4-5.1
        c-0.9-1.3-2.2-2.2-4-2.2H6.6" ois-morph-frames="1"/>
      <path className="ois-morph-frame" d="M6.6,13.8h5.7V26h4.4c1.9,0,3.5-1.6,3.5-3.5S18.6,19,16.7,19h-0.9c1.9,0,3.5-1.6,3.5-3.5s-1.6-3.5-3.5-3.5H6.6
        " ois-morph-frames="3"/>
    </g>

  </svg>
)

export default BrandDevelopmentIcon