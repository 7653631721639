import React from 'react'

const WebStrategyIcon = () => (
  <svg viewBox="0 0 32 32" data-ois="web-strategy">
    <rect x="16.6" y="5.9" className="ois-supporting-shape" width="4.6" height="7.8" web-strategy-idx="1" ois-scale-in="true" />
    <rect x="0.5" y="0.5" className="ois-supporting-stroke" width="21.8" height="14.2"/>
    <line className="ois-supporting-stroke" x1="0.5" y1="2.2" x2="22.3" y2="2.2"/>
    <line className="ois-supporting-stroke" x1="20.6" y1="2.2" x2="20.6" y2="0.5"/>
    <line className="ois-supporting-stroke" x1="5.6" y1="2.2" x2="5.6" y2="0.5"/>
    <line className="ois-supporting-stroke" x1="3.9" y1="2.2" x2="3.9" y2="0.5"/>
    <line className="ois-supporting-stroke" x1="2.2" y1="2.2" x2="2.2" y2="0.5"/>
    <rect x="9.7" y="17.3" className="ois-supporting-stroke" width="21.8" height="14.2"/>
    <line className="ois-supporting-stroke" x1="9.7" y1="19" x2="31.5" y2="19"/>
    <line className="ois-supporting-stroke" x1="29.7" y1="19" x2="29.7" y2="17.3"/>
    <line className="ois-supporting-stroke" x1="14.8" y1="19" x2="14.8" y2="17.3"/>
    <line className="ois-supporting-stroke" x1="13.1" y1="19" x2="13.1" y2="17.3"/>
    <line className="ois-supporting-stroke" x1="11.4" y1="19" x2="11.4" y2="17.3"/>

    <polyline className="ois-focus-stroke" points="12.1,23.9 14,25.8 12.1,27.6 "/>
    <line className="ois-focus-stroke" x1="17.7" y1="7.5" x2="17.7" y2="9.8"/>

    <rect x="15.4" y="4.8" className="ois-focus-stroke" width="4.5" height="7.7"/>
    <polyline className="ois-focus-stroke" points="17.7,8.6 27.1,8.6 27.1,16.8 "/>
    <polyline className="ois-focus-stroke" points="9.2,20.8 4.9,20.8 4.9,25.8 14,25.8 "/>
    <line className="ois-supporting-stroke" x1="16.5" y1="21.6" x2="25.3" y2="21.6"/>
    <line className="ois-supporting-stroke" x1="16.5" y1="23.5" x2="25.3" y2="23.5"/>
    <line className="ois-focus-stroke" x1="18.6" y1="29.1" x2="23.1" y2="29.1" web-strategy-idx="3" ois-no-draw="true"/>
    <line className="ois-supporting-stroke" x1="16.5" y1="21.6" x2="25.3" y2="21.6"/>
    <line className="ois-supporting-stroke" x1="16.5" y1="23.5" x2="25.3" y2="23.5"/>
    <line className="ois-supporting-stroke" x1="16.5" y1="25.3" x2="25.3" y2="25.3"/>
    <line className="ois-supporting-stroke" x1="16.5" y1="27.2" x2="25.3" y2="27.2"/>
    <line className="ois-supporting-stroke" x1="3.3" y1="4.8" x2="13" y2="4.8"/>
    <line className="ois-supporting-stroke" x1="3.3" y1="6.7" x2="13" y2="6.7"/>
    <line className="ois-supporting-stroke" x1="3.3" y1="8.6" x2="13" y2="8.6"/>
    <line className="ois-supporting-stroke" x1="3.3" y1="10.5" x2="13" y2="10.5"/>
    <line className="ois-supporting-stroke" x1="3.3" y1="12.4" x2="11" y2="12.4"/>

  </svg>
)

export default WebStrategyIcon