import React from 'react'

const UxIcon = () => (
  <svg viewBox="0 0 32 32" data-ois="ux">
    <rect x="1" y="4.3" className="ois-supporting-shape" width="7.3" height="11.8" ois-scale-in="true" ois-ux-idx="1"/>
    <rect x="22.4" y="15.9" className="ois-supporting-shape" width="7.3" height="11.8" ois-scale-in="true" ois-ux-idx="2"/>

    <polyline className="ois-supporting-stroke" points="10.2,5.2 10.2,14.4 2.9,14.4 2.9,2 10.2,2 10.2,2.9 "/>
    <line className="ois-supporting-stroke" x1="13.3" y1="9.9" x2="15.7" y2="9.9"/>
    <line className="ois-supporting-stroke" x1="18.4" y1="9.9" x2="20.7" y2="9.9"/>
    <polyline className="ois-supporting-stroke" points="15.7,22.3 13.3,22.3 13.3,9.9 15.7,9.9 "/>
    <polyline className="ois-supporting-stroke" points="18.4,22.3 20.7,22.3 20.7,9.9 18.4,9.9 "/>
    <line className="ois-supporting-stroke" x1="2.9" y1="6.2" x2="10.2" y2="6.2"/>
    <polyline className="ois-supporting-stroke" points="23.7,27.8 23.7,30 31,30 31,17.6 23.7,17.6 23.7,25.6 "/>
    <line className="ois-supporting-stroke" x1="31" y1="21.8" x2="23.7" y2="21.8"/>
    <polyline className="ois-focus-stroke" points="6.2,3.9 17,3.9 17,13.3 " ois-ux-idx="3"/>
    <polyline className="ois-focus-stroke" points="18.7,12.1 17,13.8 15.4,12.1 "/>
    <polyline className="ois-focus-stroke" points="17,15.8 17,26.7 27.7,26.7 " ois-ux-idx="4"/>
    <polyline className="ois-focus-stroke" points="26.5,28.3 28.2,26.7 26.5,25 "/>

  </svg>
)

export default UxIcon