import React from 'react'

const AiIcon = () => (
  <svg viewBox="0 0 32 32" data-ois="ai">
    <defs>
      <circle className="ois-focus-shape" cx="-20" cy="-20" r="1" id="ois-ai-neuron" ois-opacity-in="true"></circle>
    </defs>

    <polygon className="ois-supporting-shape" points="12.8,9 1.9,19.9 12.4,17 17.3,22.7 30.1,8.1 17.7,14.8 " ois-scale-in="true" ois-ai-idx="7"/>
    <circle className="ois-focus-stroke" cx="20" cy="19.1" r="0.8"/>

    <polyline className="ois-focus-stroke" points="22.8,26.2 20,23.5 20,19.9 " ois-ai-motionpath="1" />
    <polyline className="ois-focus-stroke" points="17.7,16.2 23,16.2 23,21.2 " ois-ai-motionpath="2" />
    <polyline className="ois-focus-stroke" points="19.8,5.1 19.8,10.6 21.3,12.1 " ois-ai-motionpath="3" />
    <polyline className="ois-focus-stroke" points="10.8,8.6 10.8,15.9 8.5,18.2 " ois-ai-motionpath="4" />
    <polyline className="ois-focus-stroke" points="9.7,26.2 9.7,21.2 13.1,17.8 13.1,12.1 " ois-ai-motionpath="5"/>
    <polyline className="ois-focus-stroke" points="15.5,21.4 12.5,21.4 12.5,23 " ois-ai-motionpath="6" />
    <path className="ois-supporting-stroke" d="M18.5,27.6c0.8,0,1.6-0.5,1.9-1.2c0.4,0.2,0.9,0.3,1.4,0.3c2.2,0,3.9-1.8,3.9-3.9c0-0.3,0-0.6-0.1-0.8
      c1.2-0.7,1.9-1.9,1.9-3.4c0-1.7-1.1-3.1-2.6-3.7c0.2-0.3,0.2-0.7,0.2-1.1c0-0.4-0.1-0.8-0.3-1.2c0.5-0.4,0.8-1,0.8-1.6
      c0-1.2-0.9-2.1-2.1-2.1c-0.3,0-0.5,0.1-0.8,0.2c-0.3-0.3-0.6-0.5-1-0.6c0.3-0.3,0.4-0.8,0.4-1.2C22.3,6,21.3,5,20.2,5
      c-0.1,0-0.3,0-0.4,0c-0.3-0.4-0.7-0.7-1.3-0.7c-0.5,0-0.8,0.3-0.8,0.8s0,21,0,21.4C17.7,27,18.1,27.6,18.5,27.6z"/>
    <path className="ois-supporting-stroke" d="M14.7,27.6c-0.8,0-1.6-0.5-1.9-1.2c-0.4,0.2-0.9,0.3-1.4,0.3c-2.2,0-3.9-1.8-3.9-3.9c0-0.3,0-0.6,0.1-0.8
      c-1.2-0.7-1.9-1.9-1.9-3.4c0-1.7,1.1-3.1,2.6-3.7C8.1,14.5,8,14.1,8,13.7c0-0.4,0.1-0.8,0.3-1.2c-0.5-0.4-0.8-1-0.8-1.6
      c0-1.2,0.9-2.1,2.1-2.1c0.3,0,0.5,0.1,0.8,0.2c0.3-0.3,0.6-0.5,1-0.6c-0.3-0.3-0.4-0.8-0.4-1.2C10.9,6,11.9,5,13,5
      c0.1,0,0.3,0,0.4,0c0.3-0.4,0.7-0.7,1.3-0.7c0.5,0,0.8,0.3,0.8,0.8s0,21,0,21.4C15.5,27,15.1,27.6,14.7,27.6z"/>
    <circle className="ois-focus-stroke" cx="8.1" cy="18.5" r="0.8"/>
    <circle className="ois-focus-stroke" cx="13.1" cy="11.5" r="0.8"/>
    <circle className="ois-focus-stroke" cx="21.8" cy="12.6" r="0.8"/>
    <circle className="ois-focus-stroke" cx="23" cy="22" r="0.8"/>
    <circle className="ois-focus-stroke" cx="12.5" cy="23.8" r="0.8"/>

    <use xlinkHref="#ois-ai-neuron" ois-ai-idx="1"></use>
    <use xlinkHref="#ois-ai-neuron" ois-ai-idx="2"></use>
    <use xlinkHref="#ois-ai-neuron" ois-ai-idx="3"></use>
    <use xlinkHref="#ois-ai-neuron" ois-ai-idx="4"></use>
    <use xlinkHref="#ois-ai-neuron" ois-ai-idx="5"></use>
    <use xlinkHref="#ois-ai-neuron" ois-ai-idx="6"></use>

  </svg>
)

export default AiIcon