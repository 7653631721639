import React from 'react'

const EyeCandyIcon = props => (
  <svg viewBox="0 0 32 32" data-ois="eye-candy">

    <g>
      <defs>
        <path id="SVGID_1_" d="M0,0v32h32V0H0z M16,19.3c-3.5,0-6.5-1.6-7.9-3.9c1.5-2.3,4.5-3.9,7.9-3.9c3.5,0,6.5,1.6,7.9,3.9
          C22.4,17.7,19.4,19.3,16,19.3z"/>
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_1_"  style={{ overflow: 'visible' }} />
      </clipPath>
      <g className="eye-candy-mask-1">
        <g ois-scale-in="true" eye-candy-idx="1">
          <path className="ois-supporting-stroke" d="M8.8,23.6c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5h4.6c-0.2,0.3-0.4,0.6-0.4,1
            c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5c0-0.4-0.1-0.7-0.4-1h4.6c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5
            V7.2c0.8,0,1.5-0.7,1.5-1.5S24,4.2,23.2,4.2s-1.5,0.7-1.5,1.5h-4.6c0.2-0.3,0.4-0.6,0.4-1c0-0.8-0.7-1.5-1.5-1.5
            c-0.8,0-1.5,0.7-1.5,1.5c0,0.4,0.1,0.7,0.4,1h-4.6c0-0.8-0.7-1.5-1.5-1.5C8,4.2,7.3,4.9,7.3,5.7S8,7.2,8.8,7.2V23.6z"/>
          <rect x="10.8" y="8.1" className="ois-supporting-stroke" width="10.4" height="14.6"/>
        </g>
      </g>
    </g>

    <g eye-candy-idx="3" className="eye-candy-mask-2">
      <circle className="ois-focus-stroke" cx="16" cy="15.4" r="2.8"/>
      <circle className="ois-focus-shape" cx="16" cy="15.4" r=".8" eye-candy-idx="4" ois-scale-in="true"/>
    </g>

    <g>
      <defs>
        <path id="SVGID_3_" d="M8.1,15.4c1.4,2.3,4.4,3.9,7.9,3.9c3.4,0,6.4-1.6,7.9-3.9c-1.4-2.3-4.4-3.9-7.9-3.9
          C12.6,11.5,9.6,13.1,8.1,15.4z"/>
      </defs>
      <clipPath id="SVGID_4_">
        <use xlinkHref="#SVGID_3_"  style={{ overflow: 'visible' }} />
      </clipPath>
      <rect x="5.25" y="1" className="eye-candy-mask-2 ois-supporting-shape" width="22.55" height="10.681" eye-candy-idx="2"/>
    </g>

    <path className="ois-focus-stroke" d="M8.1,15.4c1.4,2.3,4.4,3.9,7.9,3.9c3.4,0,6.4-1.6,7.9-3.9c-1.4-2.3-4.4-3.9-7.9-3.9
      C12.6,11.5,9.6,13.1,8.1,15.4z"/>
    <path className="ois-focus-stroke" d="M23.9,15.4c0.8,1,2.2,2.5,2.9,2.7s1-1,0.5-1.3s0.5-0.9,0.5-1.4s-1-1-0.5-1.4s0.3-1.5-0.5-1.3
      S24.6,14.4,23.9,15.4"/>
    <path className="ois-focus-stroke" d="M8.1,15.4c-0.8,1-2.2,2.5-2.9,2.7c-0.8,0.2-1-1-0.5-1.3s-0.5-0.9-0.5-1.4s1-1,0.5-1.4s-0.3-1.5,0.5-1.3
      S7.4,14.4,8.1,15.4"/>

  </svg>
)

export default EyeCandyIcon
